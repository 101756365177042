<template>
  <section v-if="showFaqSection">
    <div class="section-header">
      <h2>{{ __('faq', 'FAQ') }}</h2>
    </div>
    <ul class="faq-block">
      <li
        v-for="(faq, index) in faqs"
        :key="index"
        class="faq-item"
        :class="{ active: faq.active }"
      >
        <button @click="toggleFaqItem(index)">
          {{ faq.question }}
          <SvgIcon name="chevron" />
        </button>
        <Transition name="fade">
          <div v-show="faq.active" class="faq-answer" v-html="faq.answer"></div>
        </Transition>
      </li>
    </ul>
  </section>
</template>

<script setup lang="ts">
import SvgIcon from '@/Partials/SvgIcon.vue'
import { computed, ref, watch } from 'vue'
import { SiteTreeSectionResource } from '@/Types/SiteTreeSection'
import { RouteLocationNormalized, RouteMeta, useRoute } from 'vue-router'
import { useGlobalStore } from '@/Store/useGlobalStore'
import { findBySlug } from '@/Helpers/SiteTreeHelper'
import { getFaqs } from '@/Api/Faq'
import { __ } from '@/Helpers/i18n'
import { FaqResource } from '@/Types/Faq'

type FaqItem = {
  question: string
  answer: string
  active: boolean
}

type RouteMetaExtended = RouteMeta & {
  parent_html: RouteLocationNormalized
}

const route = useRoute()
const globalStore = useGlobalStore()

const faqs = ref<FaqItem[]>([])

const path = computed(() => {
  return (route.meta as RouteMetaExtended).parent_html
    ? (route.meta as RouteMetaExtended).parent_html.path
    : route.path
})

const menu = computed(() => globalStore.siteTree as SiteTreeSectionResource[])

const sectionId = computed(() => {
  return findBySlug(menu.value, `/faq/${path.value.substring(4)}`)?.id ?? null
})
const showFaqSection = computed(() => {
  return sectionId.value && faqs.value.length > 0
})

watch(
  () => sectionId.value,
  async (sectionId) => {
    if (sectionId) {
      faqs.value = (await getFaqs(sectionId)).map((faq: FaqResource) => {
        return {
          question: faq.name,
          answer: faq.answer,
          active: false,
        }
      })
    }
  },
  { immediate: true },
)

function toggleFaqItem(index: number) {
  faqs.value.forEach((faq, i) => {
    faq.active = i === index ? !faq.active : false
  })
}
</script>

<style scoped lang="scss">
section,
.section {
  padding: 0 2rem;
  margin-bottom: 6.8rem;
  margin-top: 3.4rem;
  @include desktop-only {
    padding: 0 3rem;
    margin-bottom: 16rem;
    margin-top: 8rem;
  }

  .section-header {
    text-align: center;
    margin-bottom: 4rem;
    @include desktop-only {
      margin-bottom: 5.2rem;
    }
  }
}

.faq-block {
  display: flex;
  flex-direction: column;
  gap: 1.6rem;
  max-width: 79.4rem;
  margin: 0 auto;
  @include desktop-only {
    gap: 2.4rem;
  }
}

.faq-item {
  border-bottom: 0.1rem solid $light-gray;
  button {
    cursor: pointer;
    background: none;
    border: none;
    width: 100%;
    display: flex;
    justify-content: space-between;
    gap: 0.8rem;
    align-items: center;
    padding: 0 0 1.6rem 0;
    font-size: 1.8rem;
    font-weight: 500;
    line-height: 130%;
    letter-spacing: -0.09rem;
    text-align: left;
    color: $black;

    svg {
      transition: all 0.3s ease;
    }

    @include desktop-only {
      font-size: 2.4rem;
      letter-spacing: -0.12rem;
      padding-bottom: 2.4rem;
    }
  }

  .faq-answer {
    overflow: hidden;
    max-height: 0;
    color: $gray-7;
    font-size: 1.4rem;
    line-height: 160%;
    letter-spacing: -0.014rem;
    @include desktop-only {
      font-size: 1.8rem;
      letter-spacing: -0.054rem;
      line-height: 150%;
    }
  }

  &.active {
    svg {
      transform: rotate(180deg);
    }

    .faq-answer {
      max-height: 60rem;
      padding-bottom: 3.2rem;
      @include desktop-only {
        padding-bottom: 5.2rem;
      }
    }
  }
}
.fade-enter-active,
.fade-leave-active {
  transition: all 0.3s ease-out;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0.2;
}
</style>
