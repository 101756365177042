// maybe this should be defined in CMS, but works for now.
export const SAMPLE_SETS_SLUG = 'sample-sets' as const
export const COLLECTION_BOXES_SLUG = 'collection-box' as const
export const PRO_PRODUCTS_SLUG = 'pro-products' as const
export const PRO_PRODUCTS_ROUTE_NAME = 'Pro products' as const

export const DEFAULT_LANG = 'en' as const
export const DEFAULT_ROUTE_NAME = 'home' as const

export const DAYS_TO_SHOW_DESIGN_TIPS_INDICATOR = 30 as const

export const SN_SHOPIFY_APP_URL =
  'https://apps.shopify.com/selfnamed-cosmetics-on-demand' as const

export const MIN_DESKTOP_PX = 992
export const MAX_MOBILE_PX = MIN_DESKTOP_PX - 1

export const STATIC_CONTENT_PATH_PREFIX = '/static/'

export const STORE_PRODUCT_UPDATED_AT_EXTRA_SECONDS = 10
